<template>
  <div class="responsive">
    <v-form class="form-style" v-model="isValid">
      <v-text-field
          class="input-el input-field-size inputNumber inputNumber-container"
          label="Mobile Number"
          placeholder="Mobile Number"
          v-model="user.phoneNumber"
          @keydown.enter="handleReset"
          :rules="phoneRules"
          type="text"
          required
          outlined
          @input="validateInput"
      >
      </v-text-field>
      <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>
      <v-btn
          color="primary"
          :disabled="!isValid"
          required
          class="input-el button-size"
          style="font-size: 22px"
          @click="handleReset"
      >
        Reset Password
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {DIGITS_ONLY_INPUT_RULE, PHONE_NUMBER_RULE} from "cpp-js-util/CppUtil";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  name: "ForgotPasswordForm",
  props: ['toggleConfirmation', 'getPhoneNumber'],
  mixins: [recaptchaMixin],
  data() {
    return {
      user: {
        phoneNumber: '',
      },
      phoneRules: [
        v => !!v || 'Number is required',
        v => (v && v.length === 11) || 'Number must have 11 digit',
        v => PHONE_NUMBER_RULE.test(v) || 'Mobile number must be a valid number starting with 01',
      ],
      isValid: false,
    }
  },
  methods: {
    async handleReset(event) {
      event.preventDefault();
      if (!this.isValid) return;
      this.$feedback.showLoading();

      let payload = JSON.parse(JSON.stringify(this.user))
      payload.recaptchaDetails = this.recaptcha;
      await this.loadAndPrepareRecaptchaDetails();

      try {
        let res = await this.$cppClient.post('/forgot-password', payload);
        this.$feedback.hideLoading();
        this.toggleConfirmation();
        this.$emit('getPhoneNumber', this.user.phoneNumber);
        this.$emit('getMaskedEmail', res?.data?.maskedEmail);
      } catch (ex) {
        this.$feedback.hideLoading();
        let {response} = ex;
        let {data} = response;
        if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          await this.$feedback.showFailed(response);
          await this.startCaptchaV2Flow();
        } else {
          await this.$feedback.showFailed(response);
        }
      }
    },
    validateInput() {
      const maxLength = 11;

      this.$nextTick(() => {
        this.user.phoneNumber = this.user.phoneNumber.replace(DIGITS_ONLY_INPUT_RULE, '')
      })

      if (this.user.phoneNumber.length > maxLength) {
        this.$nextTick(() => {
          this.user.phoneNumber = this.user.phoneNumber.slice(0, maxLength);
        })
      }
    }
  }
}
</script>

<style scoped>
.responsive {
  width: 100%;
}

.form-style {
  margin-bottom: 25px;
  margin-top: -40px;
}

.button-size {
  height: 39px;
  width: 329px;
}

.input-field-size {
  display: inline-block;
  margin-top: 90px;
  margin-bottom: 42px;
  width: 329px;
  height: 54px;
}

.input-field-size >>> .v-messages__message {
  hyphens: none !important;
}

@media only screen and (max-width: 425px) {
  .form-style {
    display: inline-block;
    width: 261px;
  }

  .button-size {
    font-size: 16px;
    width: 100%;
  }

  .input-field-size {
    width: 100%;
  }

  .input-field-size >>> .v-messages__message {
    font-size: 10px !important;
  }

  .recaptcha-v2-container {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

.recaptcha-v2-container {
  width: 329px !important;
  margin: auto;
  margin-top: -12px;
  margin-bottom: 24px;
}
</style>
