<template>
  <div class="auth-body ribbon-container">
    <div class="auth-el">
      <auth-card>
        <forgot-password-form
            v-if="!this.confirmation"
            :toggleConfirmation="toggleConfirmation"
            @getPhoneNumber="getPhoneNumber"
            @getMaskedEmail="getMaskedEmail">
        </forgot-password-form>
        <div v-if="this.confirmation" class="resend-message-block">
          <span class="primary-text">
            We emailed a password reset link to the email address <b style="color: #707070;">{{ maskedEmail }} </b> corresponding to the phone number <b style="color: #707070;">{{ userPhoneNumber }} </b>
          </span>
          <div style="margin-top: 67px;">
            <span class="resend-text">Didn’t receive an email?
              <b @click="toggleConfirmation" style="color: #ffcb15; margin-left: 5px;cursor: pointer">Resend</b>
            </span>
          </div>
        </div>
      </auth-card>
    </div>
  </div>
</template>

<script>

import AuthCard from "@/components/auth/components/AuthCard";
import ForgotPasswordForm from "@/components/forgotPassword/ForgotPasswordForm";

export default {
  name: "ForgotPassword",
  components: {ForgotPasswordForm, AuthCard},
  data() {
    return {
      confirmation: false,
      userPhoneNumber: '',
      maskedEmail: '',
    }
  },
  methods: {
    toggleConfirmation() {
      this.confirmation = !this.confirmation;
    },
    getPhoneNumber(phoneNumber) {
      this.userPhoneNumber = phoneNumber;
    },
    getMaskedEmail(maskedEmail) {
      this.maskedEmail = maskedEmail;
    }
  }
}
</script>

<style scoped>
.primary-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

.resend-text {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.55;
  letter-spacing: normal;
  color: #95989a;
}

.resend-message-block {
  display: inline-block;
  margin-top: 25px;
  width: 415px;
}

@media only screen and (max-width: 425px) {
  .resend-message-block {
    width: 100%;
  }

  .primary-text {
    font-size: 18px;
  }

  .resend-text {
    font-size: 19px;
  }
}
</style>
